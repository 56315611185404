<template>
  <both-colum-page
    :title="$t('userGroup.title_platform')"
    :formData="formData"
    :config="config"
    @search="search"
  >
    <div slot="left-title">
      <span>{{ $t('userGroup.groupTitle_platform') }}</span>
      <el-button
        v-if="$page.showBtn('SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_ADD')"
        style="position: absolute; top: 10px; right: 10px; font-size: 12px"
        icon="el-icon-plus"
        size="mini"
        @click="addFirstGroup"
      >
        {{ $t('userGroup.addFirstGroup') }}
      </el-button>
    </div>
    <base-tree
      slot="left"
      ref="baseTree"
      :url= $api.userAccountGroupFindTree
      :defaultParams="{
        scopeCompanyUid: $store.getters.company.uid,
      }"
      v-model="treeRow"
      @nodeClick="nodeClick"
    >
      <template v-slot:default="{ data }">
        <div style="display: flex; justify-content: space-between; width: 100%">
          <div>{{ data.name }}</div>
          <div
            class="groupOptions"
            v-if="data.company && data.company.id === currentCompanyId"
          >
            <i
              v-if="
                data.level !== 'THREE' &&
                $page.showBtn('SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_ADD')
              "
              class="el-icon-plus"
              @click.stop="groupMethod(data, 'add')"
            />
            <i
              v-if="
                $page.showBtn('SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_UPDATE')
              "
              class="el-icon-edit"
              @click.stop="groupMethod(data, 'edit')"
            />
            <i
              v-if="
                $page.showBtn('SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_DELETE')
              "
              class="el-icon-delete"
              @click.stop="groupMethod(data, 'del')"
            />
          </div>
        </div>
      </template>
    </base-tree>
    <template v-slot:functionButton>
      <div class="functionButton">
        <el-button size="mini" @click="openUserDetail">
          <i class="el-icon-tickets" /> {{ $t('base.view') }}
        </el-button>
      </div>
    </template>
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.userAccountFind"
      :columns="columns"
      :defaultParams="{
        scopeCompanyUid: $store.getters.company.uid,
        needParentUserAccountGroup: 'YES',
      }"
      v-model="tableRow"
    >
      <!-- 所属部门 -->
      <template v-slot:accountGroup="{ row }">
        {{ row.accountGroup.name }}
      </template>
      <!-- 所属企业 -->
      <template v-slot:company="{ row }">
        {{ row.company && row.company.name }}
      </template>
      <!-- 状态 -->
      <template v-slot:enable="{ row }">
        {{ enableObj[row.enable] }}
      </template>
      <!-- 账号类型 -->
      <template v-slot:type="{ row }">
        {{ accountTypeObj[row.type] }}
      </template>
      <!-- 可见范围 -->
      <template v-slot:scope="{ row }">
        {{ scopeEnumObj[row.scope] }}
      </template>
    </table-list>
    <!-- 部门详情 -->
    <detail
      v-if="showDetail"
      :treeRow="treeRow"
      :isFirstGroup="isFirstGroup"
      @closePage="closeTreePage"
    />
    <!-- 企业用户详情 -->
    <user-detail
      v-if="showUserDetail"
      :tableRow="tableRow"
      @closePage="showUserDetail = false"
    />
  </both-colum-page>
</template>

<script>
import Detail from './detail'
import UserDetail from './../../accountManage/platformUserAccount/detail'

export default {
  components: {
    Detail,
    UserDetail,
  },
  data() {
    return {
      currentCompanyId: this.$store.getters.company.id,
      // 搜索
      formData: {},
      config: [
        // lang:关键字
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 8,
          tagProps: {
            placeholder: this.$t('adminAccount.nameOrNotes'),
          },
        },
        // // lang:账号类型
        // {
        //   tag: 'el-select',
        //   label: this.$t('adminAccount.type'),
        //   prop: 'type',
        //   span: 8,
        //   tagProps: {
        //     options: this.$dict.userAccountType(),
        //   },
        // },
        // lang:订阅模式
        // {
        //   tag: 'el-select',
        //   label: this.$t('adminAccount.subscribeType'),
        //   prop: 'subscribeType',
        //   span: 8,
        //   tagProps: {
        //     options: this.$dict.subscribeType(),
        //   },
        // },
        // lang:可见范围
        // {
        //   tag: 'el-select',
        //   label: this.$t('adminAccount.scope'),
        //   prop: 'scope',
        //   span: 8,
        //   tagProps: {
        //     options: this.$dict.scopeEnum(),
        //   },
        // },
        // 创建时间
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 12,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 左边树
      treeRow: null,
      showDetail: false,
      isFirstGroup: false,
      // 表格页
      tableRow: null,
      showUserDetail: false,
      accountTypeObj: this.$util.listToObj(this.$dict.userAccountType()),
      enableObj: this.$util.listToObj(this.$dict.enable()),
      scopeEnumObj: this.$util.listToObj(this.$dict.scopeEnum()),
      content: [],
      columns: [
        // lang:所属部门
        {
          label: this.$t('userGroup.accountGroup'),
          prop: 'accountGroup',
        },
        // lang:所属企业
        {
          label: this.$t('adminAccount.company'),
          prop: 'company',
        },
        // lang:状态
        {
          label: this.$t('base.enable'),
          prop: 'enable',
        },
        // lang:姓名
        {
          label: this.$t('adminAccount.name'),
          prop: 'name',
        },
        // lang:手机号
        {
          label: this.$t('adminAccount.mobile'),
          prop: 'mobile',
        },
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'accountId',
        },
        // // lang:账号类型
        // {
        //   label: this.$t('adminAccount.type'),
        //   prop: 'type',
        // },
        // lang:可见范围
        // {
        //   label: this.$t('adminAccount.scope'),
        //   prop: 'scope',
        // },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
        },
      ],
    }
  },
  methods: {
    // 查询按钮点击回调
    search(isActiveQuery) {
      // 点击查询时，重置部门选择
      if (isActiveQuery) {
        this.$refs.baseTree.reset()
        delete this.formData.userAccountGroupId
      }
      // 查询
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 选择部门
    nodeClick() {
      this.formData.userAccountGroupId = this.treeRow.id
      this.search()
    },
    // 新增一级分组
    addFirstGroup() {
      this.isFirstGroup = true
      this.$store.commit('updateCmd', 'add')
      this.$store.commit('updateDialogTitle', this.$t('base.add'))
      this.showDetail = true
    },
    // 分组按钮操作方法
    groupMethod(data, method) {
      if (method === 'del') {
        this.deleteMethod('/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountGroupDelete, data)
        return
      }
      this.isFirstGroup = false
      this.treeRow = data
      this.$store.commit('updateCmd', method)
      this.$store.commit('updateDialogTitle', this.$t(`base.${method}`))
      this.showDetail = true
    },
    // 关闭分组详情页回掉
    closeTreePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        // 重新查询部门
        this.$refs.baseTree.searchList()
        // 重新查询人员
        delete this.formData.userAccountGroupId
        this.search()
      }
    },
    // 删除
    deleteMethod(url, data) {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url,
            data,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.closeTreePage('delete')
          })
        })
    },
    // 查看用户详情
    openUserDetail() {
      if (!this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      this.$store.commit('updateCmd', 'view')
      this.$store.commit('updateDialogTitle', this.$t('base.view'))
      this.showUserDetail = true
    },
  },
}
</script>

<style lang="scss">
@import '@/style/theme.scss';

.groupOptions {
  i {
    margin: 0 5px;
    color: $--color-primary;

    &:hover {
      font-weight: bold;
    }
  }
}
</style>
